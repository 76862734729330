import "./Home_custom_style.scss";
import Background from "../assets/background.webp";
import Playstore from "../assets/playstore.png";
import { LinkLocation } from "../Components/LinkLocation";

export const HomeTopSection = () => {
  return (
    <div className="home-position select-none">
      <img src={Background} alt="background_image" className="w-full" />
      <LinkLocation
        to={
          "https://play.google.com/store/apps/details?id=com.spidertechnosoft.app.xeniamobi"
        }
      >
        <img
          src={Playstore}
          alt="playstore-icon"
          className="absolute bottom-[5%] left-[9%] w-[10%]"
        />
      </LinkLocation>
    </div>
  );
};

import "./Home_custom_style.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ServiceData from "../JSON/Services.json";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "#8b8a8a",
        borderRadius: "100px",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "#8b8a8a",
        borderRadius: "100px",
      }}
      onClick={onClick}
    />
  );
}

const HomeCarousel = () => {
  const handleDragStart = (e) => e.preventDefault();
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1500,
    speed: 500,
    draggable: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    pauseOnHover: true,
    cssEase: "linear",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const serviceDetails = ServiceData.map((service) => {
    return [
      <div
        className="select-none bg-white relative overflow-hidden mr-2 select-none"
        key={service.id}
      >
        <img
          src={service.img} 
          onDragStart={handleDragStart}
          alt={service.title}
          className="w-full rounded-md bg-white transition duration-300 ease-in-out hover:scale-110"
        />
        <p className="absolute text-white bottom-0 font-Gotham-bold p-2 font-bold">
          {service.title}
        </p>
      </div>,
    ];
  });
  return <Slider {...settings}>{serviceDetails}</Slider>;
};
export default HomeCarousel;

import { Link } from "react-router-dom";

export const LinkLocation = ({ to, children, className, newTab, onClick }) => {
  return (
    <Link
      to={to}
      className={className}
      target={newTab ? "_blank" : null}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

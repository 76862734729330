import { IoIosArrowDropdown } from "react-icons/io";
import Button from "@mui/material/Button";

export const ButtonComponent = ({
  children,
  className,
  buttonClassName,
  buttonStyle,
  onClick,
}) => {
  return (
    <Button
      variant="outlined"
      style={buttonStyle}
      onClick={onClick}
      className={`${buttonClassName} w-[150px] px-4 py-2 flex gap-4 relative select-none`}
      endIcon={
        <IoIosArrowDropdown
          size={"20px"}
          className={`${className} transition duration-300 absolute right-[15px] select-none mt-[2px]`}
        />
      }
    >
      {children}
    </Button>
  );
};

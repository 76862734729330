import "./App.css";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import { LinkLocation } from "./Components/LinkLocation";
import GenericRoutes from "./Routes";
import WhatsappLogo from "./assets/whatsapp-logo.png";

function App() {
  return (
    <div>
      <Header />
      <GenericRoutes />
      <Footer />
      <div>
        <LinkLocation
          to={
            "https://api.whatsapp.com/message/7WHRUPBYFWWNA1?text=Xenia%Technologies&autoload=1&app_absent=0"
          }
        >
          <img
            src={WhatsappLogo}
            alt="whatsapp_logo"
            className="fixed bottom-[10px] right-[10px] w-12"
          />
        </LinkLocation>
      </div>
    </div>
  );
}

export default App;

const RefundPolicy = () => {
  return (
    <div>
      <section className="px-5 text-justify">
        <article>
          <h2 className="text-4xl font-semibold py-2">
            Xenia Mobi Refund Policy
          </h2>
          <p className="text-center py-2">
            Xenia Technologies is committed to providing high-quality
            application development services. This policy outlines our stance on
            refund requests.
          </p>
          <h3 className="font-semibold text-xl py-2">1. No-Refund Policy</h3>
          <p className="leading-7 ml-5">
            All payments made to Xenia Technologies for application development
            services or purchase are non-refundable. Once a client engages our
            services and makes a payment, it is understood that they have
            committed to the product after using the trial version, and no
            refunds will be issued.
          </p>
          <h3 className="font-semibold text-xl py-2">2. Scope</h3>
          <p className="leading-7 ml-5">
            This no-refund policy applies to Xenia Mobi application offered by
            Xenia Technologies, including but not limited to custom application
            development, and support services.
          </p>
          <h3 className="font-semibold text-xl py-2">
            3. Client Responsibilities
          </h3>
          <p className="leading-7 ml-5">
            Clients are responsible for thoroughly reviewing application
            requirements, specifications, and proposals before engaging Xenia
            Technologies. Any changes or additional requirements after the
            commencement of the Xenia Mobi application may result in additional
            charges but will not warrant a refund.
          </p>
          <h3 className="font-semibold text-xl py-2">
            4. Changes to the No-Refund Policy
          </h3>
          <p className="leading-7 ml-5">
            Xenia Technologies reserves the right to update or modify this
            no-refund policy at any time without prior notice. Any changes will
            be effective immediately upon posting on our website.
          </p>
          <h3 className="font-semibold text-xl py-2">5. Contact Information</h3>
          <p className="leading-7 ml-5">
            For any questions or concerns regarding our no-refund policy, please
            contact our customer support at Call
            <a href="tel:04846678888" className="text-blue-600 underline px-2">
              04846678888,
            </a>
            Call
            <a
              href="tel:+919995728888"
              className="text-blue-600 underline px-2"
            >
              +91 9995728888.
            </a>
          </p>
        </article>
      </section>
    </div>
  );
};

export default RefundPolicy;

import "./Feature.scss";
import Report from "../assets/report.png";

export const ApplicationFeature = () => {
  return (
    <div className="font-monteserrat secondary-gray-background py-6 px-6 md:px-16 py-8 lg:px-36">
      <h2 className="text-2xl font-semibold underline mb-5">
        XENIA MOBI APPLICATION FEATURES
      </h2>
      <div className="grid grid-cols md:grid-cols-2">
        <ul className="leading-8">
          <li className="flex gap-4 mr-2 md:mr-0 items-baseline">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            <p>Multi Language Interface</p>
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            <p>Simple master creation & tax billing</p>
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Support product image
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Invoice sharing in pdf format
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            A5 and A4 printing via Wi-Fi printer
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Different invoice series for different stores
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Different rates for different customers by pricelist
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0 mb-0 md:mb-5">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Area wise Customer mapping
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Bluetooth Weighing Scale Integration
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Barcode Weighing Scale Integration
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Credit management
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Sales Order Processing
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Open and Closed order report
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Purchase entry
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Sales return entry
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0 mb-0 md:mb-5">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Damage return entry
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Sales man wise sales report
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Area wise report sorting
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Item wise sales order report
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Invoice wise sales order report
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Item wise sales return report
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Invoice wise sales return report
          </li>
        </ul>
        <ul className="leading-8">
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Barcode supporting
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Receipt entry
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Quick and Easy Sale Entry
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            UPI QR code integration
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            UPI QR Code View and Printing with Invoice Amount
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Customer location mapping
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Area wise Customer Route view
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0 mb-0 md:mb-5">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Multiple user creation with access control
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Cloud data storage
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Multi store management
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Stock report
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Daily summary sales report
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Item wise sales report
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Category wise sales report
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Invoice wise sales report with customer filter
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0 mb-0 md:mb-5">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Invoice wise purchase report
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            KOT Processing
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Waiter and Table wise KOT Management
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Invoice wise purchase report
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Dine in Type Management
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Cash Counter for Invoice Processing
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Item wise purchase report
          </li>
        </ul>
      </div>
    </div>
  );
};

export const CloudFeatures = () => {
  return (
    <div className="font-monteserrat secondary-gray-background py-6 px-6 md:px-16 py-8 lg:px-36">
      <h2 className="text-2xl font-semibold underline mb-5">
        CLOUD PANEL FEATURES
      </h2>
      <div className="grid grid-cols md:grid-cols-2">
        <ul className="leading-8">
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Simple dashboard for quick analysis
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            East master creation from cloud panel like item, category,
            customers, vendors, ledger etc
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Pricelist to manage different rates for different customers
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Sales Route Plan
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Sale invoice entry
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            UPI QR code integration
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0 mb-0 md:mb-5">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            UPI QR Code View and Printing with Invoice Amount
          </li>

          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Invoice vise sales report
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Item wise sales report
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Category wise sales report
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0 mb-0 md:mb-5">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Profit Report
          </li>
        </ul>
        <ul className="leading-8">
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Sales order entry with order processing
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Sales return entry with damage return mapping
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Store wise inventory management & transfer
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Purchase entry with purchase bill details
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Credit management for vendors and customers
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Expense tracking
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0 mb-0 md:mb-5">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Accounting vouchers like Receipt, Payment, Journal, Contra etc.
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            User or sales man wise sales report
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            List of cancelled sales
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Tax reports
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Tally integration
          </li>
        </ul>
      </div>
    </div>
  );
};

export const ReportFeatures = () => {
  return (
    <div className="font-monteserrat secondary-gray-background py-6 px-6 md:px-16 py-8 lg:px-36">
      <h2 className="text-2xl font-semibold underline mb-5">
        REPORT APPLICATION FEATURES
      </h2>
      <div className="grid grid-cols md:grid-cols-2">
        <ul className="leading-8">
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Mind blowing dashboard for easy business analysis
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Daily summary report
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Invoice vise sales report
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Item wise sales report
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Category wise sales report
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            User or sales man wise sales report
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            List of cancelled sales
          </li>

          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Ledger report
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Purchase report
          </li>
          <li className="flex gap-4 items-baseline mr-2 md:mr-0">
            <img
              className="w-2.5"
              src="/images/legend/tick-icon.svg"
              alt="tick_icon"
            />
            Sales vs Purchase analysis
          </li>
        </ul>
        <div>
          <img className="w-96 m-auto" src={Report} alt="Report_image" />
        </div>
      </div>
    </div>
  );
};

import { HomeTopSection } from "./HomeTopSection";
import { HomeContent } from "./HomeContent";
const Home = () => {
  return (
    <header>
      <HomeTopSection />
      <HomeContent />
    </header>
  );
};

export default Home;

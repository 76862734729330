import { SlCallIn } from "react-icons/sl";
import { CiLocationOn } from "react-icons/ci";

const ContactUs = () => {
  const Business = [
    {
      id: 1,
      title: "Restaurant",
    },
    {
      id: 2,
      title: "Bakery",
    },
    {
      id: 3,
      title: "Van Sales",
    },
    {
      id: 4,
      title: "Cake Shop",
    },
    {
      id: 5,
      title: "Salon",
    },
    {
      id: 6,
      title: "Juice Parlour",
    },
    {
      id: 7,
      title: "Fuel Outlet",
    },
    {
      id: 8,
      title: "Vegetable Shop",
    },
    {
      id: 9,
      title: "Retail Shop",
    },
  ];

  return (
    <section>
      <div className="flex flex-wrap w-full pb-2 px-5">
        <div className="lg:w-1/2 w-full">
          <h1 className="font-monteserrat font-thin text-sm sm:text-3xl">
            Home / Contact Us
          </h1>
        </div>
      </div>
      <div className="my-2 mx-auto md:px-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
          <div className="h-[250px] sm:h-[400px] w-full">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3929.829450018056!2d76.3299581734975!3d9.948144173928004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b0873716c51b469%3A0x1c1e74a04ba6fd56!2sXenia%20Technologies%20%7C%20Solutions%20Behind%20The%20Business!5e0!3m2!1sen!2sin!4v1695192168991!5m2!1sen!2sin"
              className="left-0 top-0 h-full w-full rounded-lg select-none"
              frameBorder="0"
              allowFullScreen
              title="corporate_office"
            ></iframe>
          </div>
          <div className="h-[250px] sm:h-[400px] w-full">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14780704.339725008!2d36.851615549999984!3d25.25139079999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43c0b706afcb%3A0xf46d28710d44750!2sXenia%20Technologies%20LLC!5e0!3m2!1sen!2sin!4v1697547222279!5m2!1sen!2sin"
              className="left-0 top-0 h-full w-full rounded-lg select-none"
              frameBorder="0"
              allowFullScreen
              title="uae_office"
            ></iframe>
          </div>
        </div>
      </div>
      <div className="font-poppins px-4 py-8 md:px-8">
        <div className="flex flex-col text-center w-full lg:mb-6">
          <p className="mx-auto leading-relaxed text-2xl sm:text-[2rem]">
            Feel free to contact us
          </p>
        </div>
        <div className="grid lg:grid-cols-2">
          <section className="text-black body-font relative">
            <div className="sm:px-5 py-2 mx-auto">
              <div className="w-full px-2 mx-auto">
                <div className="flex flex-wrap -m-2">
                  <div className="p-2 w-1/2">
                    <div className="relative text-sm">
                      <label for="name" className="leading-7">
                        Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                      />
                    </div>
                  </div>
                  <div className="p-2 w-1/2">
                    <div className="relative text-sm">
                      <label for="email" className="leading-7">
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                      />
                    </div>
                  </div>
                  <div className="p-2 w-1/2">
                    <div className="relative text-sm">
                      <label for="name" className="leading-7">
                        Phone
                      </label>
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                      />
                    </div>
                  </div>
                  <div className="p-2 w-1/2">
                    <div className="relative text-sm">
                      <label for="email" className="leading-7">
                        Business
                      </label>
                      <select
                        name="business"
                        id="business"
                        className="w-full h-[42px] bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                      >
                        <option value="" disabled selected>
                          Select your business
                        </option>
                        {Business.map((business) => {
                          return (
                            <option value={business.title}>
                              {business.title}
                            </option>
                          );
                        })}
                        <option value="other">Others</option>
                      </select>
                    </div>
                  </div>
                  <div className="p-2 w-full">
                    <div className="relative text-sm">
                      <label for="message" className="leading-7">
                        Message
                      </label>
                      <textarea
                        id="message"
                        name="message"
                        className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 outline-none py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                      ></textarea>
                    </div>
                  </div>
                  <div className="p-2 w-full">
                    <button className="w-full text-white bg-[#19354c] border-0 py-2 px-8 focus:outline-none hover:bg-[#376891] rounded text-lg">
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="grid max-w-screen-lg gap-8 row-gap-10 lg:mx-auto lg:grid-cols-2 pt-5 px-2 lg:px-0 lg:pt-0 lg:h-fit">
            <div className="w-full flex flex-col max-w-md lg:mx-auto sm:flex-row pt-2">
              <div className="mr-4">
                <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-primary-100 text-primary">
                  <CiLocationOn size={"20px"} />
                </div>
              </div>
              <div>
                <h6 className="mb-3 text-xl font-bold leading-5">
                  Corporate Office
                </h6>
                <p>XENIA TECHNOLOGIES | Solutions Behind The Business</p>
                <p>2nd Floor, Gowri Arcade</p>
                <p>Gandhisquare Road, Petta,</p>
                <p>Poonithura, Kochi, Kerala-38</p>
              </div>
            </div>
            <div className="w-full flex flex-col max-w-md lg:mx-auto sm:flex-row pt-2">
              <div className="mr-4">
                <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-primary-100 text-primary">
                  <CiLocationOn size={"20px"} />
                </div>
              </div>
              <div>
                <h6 className="mb-3 text-xl font-bold leading-5">UAE Office</h6>
                <p>XENIA TECHNOLOGIES LLC</p>
                <p>Unique Business World</p>
                <p>Hamsah-A Building - C4</p>
                <p>Al Karama, Dubai - United Arab Emirates</p>
              </div>
            </div>
            <div className="w-full flex flex-col max-w-md lg:mx-auto sm:flex-row pt-2">
              <div className="mr-4">
                <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-primary-100 text-primary">
                  <SlCallIn size={"20px"} />
                </div>
              </div>
              <div>
                <h6 className="mb-3 text-xl font-bold leading-5">
                  Customer Care
                </h6>
                <p>Tel: 0484 667 8888</p>
                <p>Mob: +91 999 5728 888</p>
                <p>Mob: +91 903 7300 788</p>
                <p>Mail: helpdesk@xeniapos.com</p>
              </div>
            </div>
            <div className="w-full flex flex-col max-w-md lg:mx-auto sm:flex-row pt-2">
              <div className="mr-4">
                <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-primary-100 text-primary">
                  <SlCallIn size={"20px"} />
                </div>
              </div>
              <div>
                <h6 className="mb-3 text-xl font-bold leading-5">Enquiry</h6>
                <p>
                  <b>India</b>
                </p>
                <p>Mob: +91 974 73 8888</p>
                <p>Mail: sales@xeniapos.com</p>
                <p>
                  <b>UAE</b>
                </p>
                <p>Mob: +971 55 276 2552</p>
                <p>Mail: uae@xeniapos.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ContactUs;

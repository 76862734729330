const PrivacyPolicy = () => {
  return (
    <div id="main" role="main">
      <div>
        <section className="px-5 text-justify">
          <article>
            <h2 className="m-0 text-3xl font-semibold py-2">
              Xenia Mobi Privacy Policy
            </h2>
            <p className="py-2 text-center">
              This Privacy Policy applies to the Android app users of Xenia Mobi
              an accounting app of Xenia Technologies. We recognizes the
              importance of maintaining your policy. We value your privacy and
              appreciate your trust in us. This policy describes how we treat
              user information we collect in our app. This privacy policy to
              current and former users. By registering and/or using our app or
              website, you agree to this privacy policy. xeniamobi.com website
              and its android app are a property of Xenia Technologies. Company.
            </p>
            <h3 className="text-2xl font-semibold py-2">
              Permissions You Grant To Us
            </h3>
            <ul className="list-disc ml-5 leading-7">
              <li>
                Camera to take the picture of the bills etc to be attached with
                transactions.
              </li>
              <li>Contact to auto fill the detail during party creation.</li>
              <li>
                SMS to send automatic message or remainder message to your
                parties if transaction message is enabled or you send the
                remainder to your party for payment.
              </li>
              <li>
                Storage to send automatic message or reminder message to your
                parties if transaction message is enabled or you send the
                reminder to your party for payment.
              </li>
              <li>
                Telephone to help you make call to your party directly from
                within the app.
              </li>
              <li>Location to customize the app according to your region.</li>
            </ul>
            <h3 className="text-2xl font-semibold py-2">
              Information We Collect
            </h3>
            <p className="leading-7 pb-2">
              Contact information. we might collect your name, email, mobile
              number, device id (imei no), phone number
              registration/activation/purchase/transaction/date, street, city,
              state, pincode, country and ip address.
            </p>
            <p className="leading-7 pb-2">
              Payment and billing information. We might collect your billing
              name, billing address and payment method when you by an app or
              add-on or software or solution. We Never collect your credit card
              number or credit card expiry date or other detail pertaining to
              your credit card on our website. Credit card information will be
              obtained and processed by our online payment partner.
            </p>
            <p className="leading-7 pb-2">
              We collect information directly from you. We collect information
              directly from you when you place order for or buy add-on or app
              software or solution. We also collect information if you post a
              comment on our website or ask us a question through phone or
              email.
            </p>
            <p className="leading-7 pb-2">
              We collect information from you passively. We use tracking tools
              like Google Analytics, Google Webmaster, Google ad words, Google
              ad words Express, browser cookies and web beacons for collecting
              information about your usage of our apps/website.
            </p>
            <h3 className="text-2xl font-semibold py-2">
              Use Of Your Personal Information
            </h3>
            <p className="leading-7 pb-2">
              We use information to contact you : we might use the information
              you provide to contact you for confirmation of a purchase on our
              website or for other promotional purpose. We use information to
              respond to your request or question. We might use your information
              to confirm your order or purchase or specification for your
              typical needs.
            </p>
            <p className="leading-7 pb-2">
              We use information to improve your products and services. We might
              use your information to customize your experience with us. This
              could include displaying content based upon your preference. We
              use information to look at site trends and customer interests. We
              may use your information to make our website and products better.
              We may combine information we get from you with information about
              you we get from third parties. We use information for security
              purpose. We may use information to protect our company, our
              customers, or our websites.
            </p>
            <p className="leading-7 pb-2">
              We use information for making purpose. We might send you
              information about special promotions or offers. We might also tell
              you about new features or products. These might be our own offers
              or products, or products we think you might find interesting. Or
              for example, If you buy add-on from us we enroll you in our mobile
              app promo list or newsletter.
            </p>
            <p className="leading-7 pb-2">
              We use information to send you transactional communications. We
              might send you emails or sms or gcm notification about your
              account or an order or a purchase or promo. We use information as
              otherwise permitted by law.
            </p>
            <p className="leading-7 pb-2">
              This privacy policy was last updated on 05/10/2019. From time to
              time we may change our privacy practices. We will notify you of
              any material changes to this policy as required by law. We will
              also post an update copy on our website. Please check our site
              periodically for updates.
            </p>
          </article>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

import { Route, Routes } from "react-router-dom";
import Home from "./Home/Home";
import Feature from "./Features/Feature";
import PrivacyPolicy from "./SupportPage/PrivacyPolicy";
import RefundPolicy from "./SupportPage/RefundPolicy";
import ContactUs from "./SupportPage/ContactUs";
import PageNotFound from "./SupportPage/PageNotFound";

export const RouteNavigation = {
  home: "/",
  feature: "/feature",
  privacy: "/privacypolicy",
  refund: "/RefundPolicy",
  contact: "/contact-us",
  pagenotfound: "*",
};

const GenericRoutes = () => {
  return (
    <Routes>
      <Route path={RouteNavigation.home} element={<Home />} />
      <Route path={RouteNavigation.feature} element={<Feature />} />
      <Route path={RouteNavigation.privacy} element={<PrivacyPolicy />} />
      <Route path={RouteNavigation.refund} element={<RefundPolicy />} />
      <Route path={RouteNavigation.contact} element={<ContactUs />} />
      <Route path={RouteNavigation.pagenotfound} element={<PageNotFound />} />
    </Routes>
  );
};
export default GenericRoutes;
